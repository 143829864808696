"use client";

import {User} from "@supabase/supabase-js";
import {UserProfile} from "@/app/lib/profile/user-profile";
import {useAtomValue, useSetAtom} from "jotai/index";
import {store} from "@/app/lib/data/appState";
import {useLoggedInUser} from "@/components/common/hooks/useLoggedInUser";
import {useCallback} from "react";
import {fetchProfile} from "@/components/common/hooks/fetch-profile";
import * as Sentry from "@sentry/nextjs";
import {useLocalBrowserAffiliate} from "@/components/common/hooks/useLocalBrowserAffiliate";
import {DEBUG_PROFILE} from "@/components/common/hooks/profile-utils";

const DEBUG = DEBUG_PROFILE;

export function useProfile(): {
    loadingUser: boolean,
    loadingProfile: boolean,
    user: User | undefined,
    profile: UserProfile | undefined,
    reloadProfile: () => void;
    authClient: ReturnType<typeof useLoggedInUser>['authClient'];
} {
/*
    useEffect(() => {
        DEBUG && console.log('Running hook useProfile');
    }, []);
*/

    const {loadingUser, user, authClient} = useLoggedInUser();
    const localBrowserAffiliate = useLocalBrowserAffiliate();

    const setAppState = useSetAtom(store.state);
    const loadingProfile = useAtomValue(store.loadingProfile);
    const profile = useAtomValue(store.profile);
    const setLoadingProfile = useCallback((value: boolean) => {
        setAppState(state => {
            state.loadingProfile = value;
        })
    }, [setAppState]);
    const setProfile = useCallback((profile: UserProfile | undefined) => {
        setAppState(state => {
            state.profile = profile;
        })
    }, [setAppState]);

    const reloadProfile = useCallback(() => {
        if (user == null) {
            return;
        }
        setLoadingProfile(true);
        setTimeout(() => {
            fetchProfile(user, true, localBrowserAffiliate)
                .then((profile) => {
                    setProfile(profile);
                })
                .catch(e => {
                    Sentry.captureException(e);
                })
                .finally(() => {
                    setLoadingProfile(false);
                })
        }, 0);
    }, [setLoadingProfile, setProfile, user, localBrowserAffiliate]);

    if (DEBUG) {
        console.log('[useProfile]', 'loadingUser', loadingUser, 'loadingProfile', loadingProfile, 'user', user, 'profile', profile);
    }

    return {
        loadingUser,
        loadingProfile: loadingUser || loadingProfile,
        user,
        profile,
        reloadProfile,
        authClient
    };
}
