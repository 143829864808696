import {useAtomValue} from "jotai/index";
import {store} from "@/app/lib/data/appState";
import {
    CustomAffiliateKey,
    CustomAffiliateParam, DEBUG_AFFILIATES,
    locallyGet, safeGetToltReferral, ToltAffiliateKey, ToltAffiliateParam
} from "@/components/common/affiliate-utils";
import {ResolvedAffiliate} from "@/app/lib/pricing/types";
import {useProfile} from "@/components/common/hooks/useProfile";

const DEBUG = DEBUG_AFFILIATES;

export function useLocalBrowserAffiliate(): ResolvedAffiliate | undefined {
    const storeCustomAffiliateId = useAtomValue(store.customAffiliateId);
    const localCustomAffiliateId = locallyGet(CustomAffiliateKey);
    const storeToltAffiliateId = useAtomValue(store.toltAffiliateId);
    const localToltAffiliateId = locallyGet(ToltAffiliateKey);

    // try custom affiliates/partners first
    const customAffiliateId = storeCustomAffiliateId ?? localCustomAffiliateId;
    if (customAffiliateId != null) {
        const result = {
            param: CustomAffiliateParam,
            affiliateId: customAffiliateId,
            isCustom: true,
        };
        DEBUG && console.log('Resolved custom affiliate', result);
        return result;
    }

    // then try Tolt affiliates
    const toltAffiliateId = storeToltAffiliateId ?? localToltAffiliateId;
    if (toltAffiliateId != null) {
        const result = {
            param: ToltAffiliateParam,
            affiliateId: toltAffiliateId,
            isCustom: false,
        };
        DEBUG && console.log('Resolved Tolt affiliate', result);
        return result;
    }

    // finally, try Tolt API in the browser
    const toltReferral = safeGetToltReferral();
    if (toltReferral != null) {
        const result = {
            param: ToltAffiliateParam,
            affiliateId: toltReferral,
            isCustom: false,
        };
        DEBUG && console.log('Resolved affiliate from Tolt (in the browser)', result);
        return result;
    }

    DEBUG && console.log('No affiliate resolved');
    return undefined;
}
