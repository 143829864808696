import {User} from "@supabase/supabase-js";
import {UserProfile} from "@/app/lib/profile/user-profile";
import {DEBUG_PROFILE} from "@/components/common/hooks/profile-utils";
import {ResolvedAffiliate} from "@/app/lib/pricing/types";

export const DEBUG = DEBUG_PROFILE;

export async function fetchProfile(
    user: User | null | undefined,
    includeDiscounts: boolean,
    localBrowserAffiliate: ResolvedAffiliate | undefined) {
    DEBUG && console.log(`Fetching profile${includeDiscounts ? ' with discounts' : ''}...`);
    try {
        const profile: UserProfile | undefined = user == null ?
            undefined : await fetchProfileFromDb(user, includeDiscounts, localBrowserAffiliate);
        DEBUG && console.log('Fetched profile', profile);
        return profile;
    } catch (e) {
        console.error('[fetchProfile] Failed to fetch profile', e);
        return undefined;
    }
}

async function fetchProfileFromDb(
    user: User,
    includeDiscounts: boolean,
    localBrowserAffiliate: ResolvedAffiliate | undefined): Promise<UserProfile | undefined> {
    DEBUG && console.log(`Fetching profile from DB${includeDiscounts ? ' with discounts' : ''}...`);
    const response = await fetch('/api/profile', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            includeDiscounts,
            localBrowserAffiliate: localBrowserAffiliate != null ? localBrowserAffiliate : null,
        }),
    });
    if (!response.ok) {
        throw new Error('[fetchProfileFromDb] Failed to fetch profile from DB');
    }
    const profile = await response.json() as UserProfile | null;
    if (profile == null) {
        DEBUG && console.log('No profile in DB for user.', user);
        return undefined;
    }
    DEBUG && console.log('Fetched profile', profile, 'for user', user);
    return profile;
}
